.receipt-container {
  max-width: 600px;
  margin: 0 10px;
  border: 1px solid #000;
  padding: 20px;
  font-family: monospace;
  background: #fff;
  border-radius: 20px;
}

.receipt-header, .receipt-footer {
  text-align: center;
  border-bottom: 1px dashed #000;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.receipt-header h1, .receipt-footer p {
  margin: 0;
  padding: 0;
}

.receipt-body {
  max-height: 400px;
  overflow-y: auto;
}

.receipt-item {
  border-bottom: 1px dashed #000;
  padding: 10px 0;
}

.receipt-item:last-child {
  border-bottom: none;
}

.receipt-item h5 {
  margin: 0 0 10px 0;
}

.receipt-details p {
  margin: 5px 0;
}

.text-danger {
  color: red;
}




.contact-section {
  height: 80vh;
  background-color: #eeeeee;
}

.contact-section .title {
  font-size: 2.5rem;
  font-weight: bold;
}

.contact-section .contact-info {
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
}

.contact-section .text-orange {
  color: #d9534f;
  font-weight: bold;
  font-size: 1rem;
}






.testimonial-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: transform 0.3s;
}

.testimonial-card:hover {
  transform: scale(1.05);
}

.testimonial-card .card-body {
  padding: 20px;
}

.testimonial-card .testimonial-info {
  margin-top: 15px;
}

.testimonial-card .testimonial-name {
  font-size: 18px;
  font-weight: bold;
}

.testimonial-card .testimonial-position {
  font-size: 14px;
  color: #777;
}

.testimonial-card .quote {
  font-size: 24px;
  color: #777;
}

.petal-test-back{ 
  background-color: #eeeeee;

}




.content-inner {
  padding: 50px 0;
}

.petal-back { 
  background-color: #eeeeee;

}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 15px;
}

.image-grid img {
  width: 100%;
  height: 250px;
  border-radius: 8px;
  object-fit: cover;
}

.text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}



.text-content p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.text-content .btn {
  align-self: start;
}

@media (max-width: 768px) {
  .image-grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, auto);
  }

  .text-content .title {
    font-size: 28px;
  }

  .text-content p {
    font-size: 14px;
  }

  .text-content .btn {
    width: 100%;
    text-align: center;
  }
}





.main-bnr-three {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.top-banner-text {
  position: absolute;
  top: 20px; 
  width: 100%;
  z-index: 10;
}

.top-banner-text .sub-title {
  font-size: 24px;
  color: #000;
}

.center-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center-content .bnr-text {
  font-size: 48px;
  color: #000;
  margin-bottom: 20px; /* Adjust this value as needed for spacing */
}

.banner-btn .btn {
  margin: 0 10px;
  padding: 10px 20px;
  cursor: pointer;
}



.social-icons {
  position: absolute;
  bottom: 20px; /* Adjust this value as needed for vertical spacing from the bottom */
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 10;
  gap: 60px
}



.petals-text{ 
  font-size: 30px;
  font-weight: 600;
  font-family: Georgia, 'Times New Roman', Times, serif;


}







.category-menu-wrapper {
  position: sticky;
  top: 80px;
  z-index: 1000;
  background-color: #fff;
  padding: 10px 0;
  overflow-x: auto;
  white-space: nowrap;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.category-menu {
  display: flex;
  align-items: center;
}

.newshadow { 
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.category-item {
  margin-right: 15px;
}

.category-item.active {
  /* background-color: #007bff; */
  border: 1px solid black;
}

.container-fluid {
  padding-top: 20px; /* Adjust based on the height of your sticky category menu */
}

.waitlist-page-container {
  border: 2px solid #e9ecef;
  border-radius: 20px;
  padding: 20px;
}


.swal2-custom-z-index {
  z-index: 9999999; /* Adjust this value as needed to be higher than your modal's z-index */
}




















.hover-zoom {
  overflow: hidden;
}

.hover-zoom img {
  transition: all 0.3s ease 0s;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .addMargin {
    margin-top: 50px;
  }
}

.date-row {
  position: relative;
  overflow: hidden;
  transition: 1s ease-in-out;
}



.scroll-view {
  display: flex;
  overflow-x: auto;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for IE/Edge */
}

.scroll-view::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome/Safari/Opera */
}

.date-box {
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 15px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100px; /* Set a minimum width for each date box */
  text-align: center;
  position: relative;
  border-radius: 15px;
  max-height: 100px;
  width: 150px;
}

.day {
  /* Styling for the weekday */
  font-weight: bold;
  width: 60px;
}

.date {
  /* Styling for the date */
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.scroll-view::before,
.scroll-view::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px; /* Set the width of the scroll viewer */
  z-index: 1;
}

.scroll-view::before {
  left: 0;
  background: linear-gradient(to right, transparent, white); /* Gradient to create fade effect */
}

.scroll-view::after {
  right: 0;
  background: linear-gradient(to left, transparent, white); /* Gradient to create fade effect */
}

.filters {
  background-color: #f8f9fa; /* Change to desired background color */
  border-radius: 5px;
  position: sticky;
  top: 80px; /* Adjust the distance from the top of the screen */
}

.col-md-2 {
  flex: 0 0 auto; /* Ensure the column does not grow or shrink */
  max-width: 200px; /* Adjust the maximum width of the column */
}

.filter-item {
  margin-bottom: 5px; /* Adjust vertical spacing between filter items */
}

.filter-item.active {
  color: #f3f737;
}

.filter-link {
  display: block;
  padding: 10px;
  border-radius: 5px;
  color: black; /* Change to desired text color */
  text-decoration: none;
  transition: background-color 0.3s ease;
  font-weight: 650;
  
}

.filter-link:hover {
  background-color: #f4da64; /* Change to desired active filter background color */


}


.hover-zoom img:hover {
  transform: scale(1.25);
}

.checkout-card-body{ 
  scale: 1;
}

.checkout-card-body:hover {
  transform: scale(1.01);
  transition: all 0.3s ease 0s;
  cursor: pointer;
}

.fa-trash-alt{ 
  color: rgb(0, 0, 0);
  font-size: 16px;
}

.fa-trash-alt:hover {
  color: rgb(65, 128, 70);  
}


.checkbox-wrapper-47 input[type="checkbox"] {
  display: none;
  visibility: hidden;
}




.checkbox-wrapper-47 label {
  position: relative;
  padding-left: 2em;
  padding-right: 1em;
  line-height: 2;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  color: black;
  font-weight: 500;
  margin: 5px;
}

.checkbox-wrapper-47 label:before {
  box-sizing: border-box;
  content: " ";
  position: absolute;
  top: 0.3em;
  left: 0;
  display: block;
  width: 1.4em;
  height: 1.4em;
  border: 2px solid #928c8c;
  border-radius: 6px;
}

.checkbox-wrapper-47 input[type=checkbox]:checked + label {
  padding-left: 1em;
  color: #0f5229;
}

.checkbox-wrapper-47 input[type=checkbox]:checked + label:before {
  top: 0;
  width: 100%;
  height: 2em;
  border-color: #2cbc63;
}

.checkbox-wrapper-47 label,
.checkbox-wrapper-47 label::before {
  transition: 0.25s all ease;
}

.quantity-input {
  display: flex;
  align-items: center;
}

.quantity-input button {
  background-color: #f8f9fa;
  border: none;
  color: #000;
  font-weight: bold;
  cursor: pointer;
}

.quantity-input button:hover {
  background-color: #e9ecef;
}

.quantity-input input[type="number"] {
  width: 50px;
  text-align: center;
}
/* CSS to style the scrollbar */
/* Works on Chrome, Edge, and Safari */

/* The container with the scrollbar */
.overflow-auto::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

/* Handle on the scrollbar */
.overflow-auto::-webkit-scrollbar-thumb {
  background: #ccc; /* Color of the scrollbar handle */
  border-radius: 5px; /* Rounded corners for the scrollbar handle */
}

/* Handle on hover */
.overflow-auto::-webkit-scrollbar-thumb:hover {
  background: #aaa; /* Color of the scrollbar handle on hover */
}

/* Track */
.overflow-auto::-webkit-scrollbar-track {
  background: #f2f2f2; /* Color of the scrollbar track */
}

/* Track on hover */
.overflow-auto::-webkit-scrollbar-track:hover {
  background: #ddd; /* Color of the scrollbar track on hover */
}

@media screen and (max-width: 768px) {
  .card .card-body .rounded-3 {
    display: none; /* Hide the image */
  }
  .card-body h5,
  .card-body p,
  .card-body .fw-normal {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }

  .card-body .fw-normal {
    font-weight: normal; /* Ensure font weight is normal */
  }
}

body { 
  margin: 0;
  padding: 0;
}
